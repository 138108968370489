<template>
  <v-container>
    <v-row class="mt-8">
      <span class="text-h5 mx-auto grey-darken-4--text font-weight-bold">{{
        $t(passwordHeaderText)
      }}</span>
    </v-row>
    <v-row class="mt-4">
      <span class="mx-auto grey-darken-2--text font-weight-medium body-2">{{
        $t(passwordBodyText)
      }}</span>
    </v-row>

    <v-row class="mt-6">
      <v-col
        class="mx-auto pa-3"
        cols="12"
        sm="9"
        :lg="passwordBodyWidth"
        :md="passwordBodyWidth"
        space-around
      >
        <v-row no-gutters>
          <v-col cols="12" sm="10" md="6" lg="6" class="mx-auto">
            <v-card
              class="mx-auto pb-2 rounded-lg"
              outlined
              color="grey lighten-4"
            >
              <v-card-title class="body-2 mb-0 font-weight-medium">
                {{ $t("passwordMustText") }}
              </v-card-title>

              <v-container caption font-weight-regular class="ml-4">
                <v-row>
                  <v-icon
                    size="12"
                    class="mr-1"
                    :color="
                      containsEightCharacters ? 'success' : 'grey darken-4'
                    "
                    >mdi-checkbox-blank-circle</v-icon
                  >
                  <span
                    :class="
                      containsEightCharacters
                        ? 'success--text'
                        : 'grey-darken-4--text'
                    "
                  >
                    {{ $t("charactersLengthText") }}
                  </span>
                </v-row>
                <v-row>
                  <v-icon
                    size="12"
                    class="mr-1"
                    :color="containsNumber ? 'success' : 'grey darken-4'"
                    >mdi-checkbox-blank-circle</v-icon
                  >
                  <span
                    :class="
                      containsNumber ? 'success--text' : 'grey-darken-4--text'
                    "
                  >
                    {{ $t("numberText") }}
                  </span>
                </v-row>
                <v-row>
                  <v-icon
                    size="12"
                    class="mr-1"
                    :color="
                      containsUppercase && containsLowercase
                        ? 'success'
                        : 'grey darken-4'
                    "
                    >mdi-checkbox-blank-circle</v-icon
                  >
                  <span
                    :class="
                      containsUppercase && containsLowercase
                        ? 'success--text'
                        : 'grey-darken-4--text'
                    "
                  >
                    {{ $t("upperLowerLettersText") }}
                  </span>
                </v-row>
                <v-row>
                  <v-icon
                    size="12"
                    class="mr-1"
                    :color="
                      containsSpecialCharacter ? 'success' : 'grey darken-4'
                    "
                    >mdi-checkbox-blank-circle</v-icon
                  >

                  <span
                    :class="
                      containsSpecialCharacter
                        ? 'success--text'
                        : 'grey-darken-4--text'
                    "
                  >
                    {{ $t("specialCharacterText") }}
                  </span>
                </v-row>
                <v-row>
                  <div class="font-italic ml-4 text-wrap mr-3">
                    {{ $t("avoidDictionaryText") }}
                  </div>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
        <v-form>
          <v-container fluid pa-0 mt-5 mb-9>
            <v-row no-gutters>
              <v-col cols="12" sm="10" md="6" lg="6" class="mx-auto">
                <v-text-field
                  v-model="password"
                  outlined
                  hide-details="true"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  :label="$t('enterPassword')"
                  class="mb-1"
                  @input="checkPassword"
                  @click:append="showPassword = !showPassword"
                  @click.right.prevent
                  @copy.prevent
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="10" md="6" lg="6" class="mx-auto mb-1">
                <v-row flex class="ma-0" align="center">
                  <v-col class="flex-grow-1 pl-0">
                    <v-progress-linear
                      class="mx-auto ma-0"
                      :aria-label="score.value"
                      rounded
                      height="7"
                      :color="score.color"
                      :value="score.value"
                    ></v-progress-linear>
                  </v-col>
                  <v-col class="px-0 py-3 flex-grow-0">
                    <span
                      :class="score.color + '--text'"
                      class="text-no-wrap caption font-weight-regular"
                    >
                      {{ score.strength }}</span
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="10" md="6" lg="6" class="mx-auto">
                <v-text-field
                  v-model="confirmPassword"
                  outlined
                  :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  :label="$t('confirmPassword')"
                  class="mb-1"
                  :rules="[rules.confirmPasswordRules]"
                  @click:append="showConfirmPassword = !showConfirmPassword"
                  @click.right.prevent
                  @copy.prevent
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="ma-0">
              <v-col cols="12" sm="10" lg="4" md="4" class="mx-auto pa-0">
                <v-btn
                  block
                  class="mr-4 text-none rounded-lg"
                  color="primary"
                  :disabled="!checkValidPassword"
                  @click="submit"
                >
                  {{ $t(passwordButtonText) }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import zxcvbn from "zxcvbn";
export default {
  name: "SetPassword",
  props: {
    passwordHeaderText: {
      type: String,
      default: () => {
        return "setPassword";
      },
    },
    passwordBodyText: {
      type: String,
      default: () => {
        return "createPasswordText";
      },
    },
    passwordButtonText: {
      type: String,
      default: () => {
        return "confirm";
      },
    },
    passwordBodyWidth: {
      type: String,
      default: () => {
        return "9";
      },
    },
  },
  data() {
    return {
      showPassword: false,
      showConfirmPassword: false,
      password: "",
      confirmPassword: "",
      validPassword: false,
      containsEightCharacters: false,
      containsNumber: false,
      containsUppercase: false,
      containsLowercase: false,
      containsSpecialCharacter: false,
      strength: "",
      rules: {
        confirmPasswordRules: (value) =>
          value === this.password ||
          "Password and confirm password must be match.",
      },
    };
  },

  computed: {
    checkValidPassword() {
      return this.password === this.confirmPassword && this.validPassword;
    },
    score() {
      const result = zxcvbn(this.password);

      switch (result.score) {
        case 4:
          return {
            color: "primary",
            value: 100,
            strength: "Very Strong",
          };
        case 3:
          return {
            color: "success",
            value: 75,
            strength: "Strong",
          };
        case 2:
          return {
            color: "orange",
            value: 50,
            strength: "Average",
          };
        case 1:
          return {
            color: "error",
            value: 25,
            strength: "Weak",
          };
        default:
          return {
            color: "grey",
            value: 0,
            strength: "Weak",
          };
      }
    },
  },

  methods: {
    async submit() {
      const updateUserStatus = await this.$store.dispatch("updateUser", {
        id: this.$store.state.User.user.user_id,
        password: this.password,
        token: this.$store.state.User.token,
      });
      if (updateUserStatus) {
        this.$emit("setUserPassword");
      } else {
        this.$emit("error");
      }
    },

    checkPassword() {
      this.passwordLength = this.password.length;
      const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

      if (this.passwordLength >= 8 && this.passwordLength <= 64) {
        this.containsEightCharacters = true;
      } else {
        this.containsEightCharacters = false;
      }

      this.containsNumber = /\d/.test(this.password);
      this.containsUppercase = /[A-Z]/.test(this.password);
      this.containsLowercase = /[a-z]/.test(this.password);
      this.containsSpecialCharacter = format.test(this.password);

      if (
        this.containsEightCharacters === true &&
        this.containsSpecialCharacter === true &&
        this.containsUppercase === true &&
        this.containsLowercase === true &&
        this.containsNumber === true
      ) {
        this.validPassword = true;
      } else {
        this.validPassword = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
